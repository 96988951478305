
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexyyDxRrmfyqMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/index.vue?macro=true";
import { default as _91id_93e1WcxxNTM6Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/bundle-choices/[id].vue?macro=true";
import { default as indexH4qCUpvCgpMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/bundle-choices/index.vue?macro=true";
import { default as _91id_93FV5sViRRGMMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/bundles/[id].vue?macro=true";
import { default as index2lMvz07Y6JMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/bundles/index.vue?macro=true";
import { default as indexu6MIhyW1fQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_93xxoiDBnGFFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as indexjwuePi5dLcMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as indexS2eccWh0BzMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/menus/index.vue?macro=true";
import { default as indexPMh6S0RpOsMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexNOvoXvxWYwMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93iADjehGTxDMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_934vbrAGlEvsMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexe8WPVzLgLuMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as indexBh4Kos9LOZMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93GjzFY6tfoCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as index49mSWhgESlMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93sS4rm7wcKpMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/products/[id].vue?macro=true";
import { default as index42PpJFgMaNMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/products/index.vue?macro=true";
import { default as merchantszE6442srtTMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/merchants.vue?macro=true";
import { default as _91accountId_93oFTfLPEnFlMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue?macro=true";
import { default as indexXce9g32ZbhMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/accounts/[id]/index.vue?macro=true";
import { default as _91id_93YbHyqFUeyjMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/accounts/[id].vue?macro=true";
import { default as indexxNqig9fxyLMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/accounts/index.vue?macro=true";
import { default as credentials8FSiGGjHFlMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as indexHJFVAzAYsyMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as _91id_93yIqJwnKzx3Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_93iv2wmAKuPOMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as indexvYYFq2NfPmMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indexi2FBoKeTCDMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/index.vue?macro=true";
import { default as index12WQgSpMi8Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/customer-sites/[id]/index.vue?macro=true";
import { default as payment_45methods97lRBMC2mvMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/customer-sites/[id]/payment-methods.vue?macro=true";
import { default as _91id_93R5cpug0qhtMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/customer-sites/[id].vue?macro=true";
import { default as indexFS5O1oJflSMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/customer-sites/index.vue?macro=true";
import { default as _91id_93nq6NNjyAsYMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as indexxxyLsXlrnPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexEGkZieHUmBMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as _91id_93E7E56JTCZ8Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as indexmBl0vpVCvBMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/employees/index.vue?macro=true";
import { default as index9qIzDOGPXMMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/images/index.vue?macro=true";
import { default as indexJIVdQZf8upMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/kitchens/index.vue?macro=true";
import { default as modifier_45groupsIQQiJVo7K0Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/kitchens/modifier-groups.vue?macro=true";
import { default as kitchensUZGItNjBO5Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/kitchens.vue?macro=true";
import { default as indexNf3nNTMJbQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stock/index.vue?macro=true";
import { default as modifier_45recipesOITcct108fMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stock/modifier-recipes.vue?macro=true";
import { default as variant_45recipesJQkIfbVdhRMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stock/variant-recipes.vue?macro=true";
import { default as stockan6A1dxPVuMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingpzLZpRU3bAMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as indexhC23EUjn91Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as kitchenIQZGO9pnXGMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id]/kitchen.vue?macro=true";
import { default as stockgKq7t2KShdMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93TkpthPaiGUMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as indexCJRsrAAIl2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/index.vue?macro=true";
import { default as indexd8iDqGmRCKMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_93Nns9AE1mQRMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as indexYBgfEAp0z5Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as channel_45settlementsHmqMuSlQzeMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue?macro=true";
import { default as indexjY5davKTNyMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as _91id_93tBuWsyOlGoMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexe0u4GomanUMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as indexQDl6w5xHDIMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/profile/index.vue?macro=true";
import { default as cancellationsobFVrjq7lbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/cancellations.vue?macro=true";
import { default as cash_45transfers68UfePNtgaMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/cash-transfers.vue?macro=true";
import { default as closing_45errorsEEt9UyyI3LMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/closing-errors.vue?macro=true";
import { default as downloadVjlmWmRMY4Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/download.vue?macro=true";
import { default as efficiencyic87hlkMDBMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/efficiency.vue?macro=true";
import { default as indexauKNVsEu77Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/index.vue?macro=true";
import { default as paymentsOAsinHHCkpMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/payments.vue?macro=true";
import { default as production7sq0g9yPz5Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/production.vue?macro=true";
import { default as reservationsMiQNJQiZ2uMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/reservations.vue?macro=true";
import { default as reviewsxPkHpc4hhPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/reviews.vue?macro=true";
import { default as tipsNR8Qv919uEMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/tips.vue?macro=true";
import { default as accountsujy9stjhl4Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/accounts.vue?macro=true";
import { default as financesBxbOLAXWbbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/finances.vue?macro=true";
import { default as index5WZX2FdoClMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/index.vue?macro=true";
import { default as integrationsNVQDhFKp9OMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/integrations.vue?macro=true";
import { default as operationsjci6uRGSWxMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/operations.vue?macro=true";
import { default as settings89USpWnSM2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings.vue?macro=true";
import { default as indexGtFkIib2kjMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as logegRKWcN39uMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as orderst9OrDXpDF7Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93nq2fqtdrtFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexgj8nc9lX1VMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93oa1Evm29oJMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_93AXVRNiA0TrMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/orders/[id].vue?macro=true";
import { default as indexhIr9cSpUU3Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/orders/index.vue?macro=true";
import { default as bookkeeping6PhcWrXiMEMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlements67vFX3UPhnMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexCZBejVTP4bMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logHde3bUblhPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as ordersZeTRnd1UQkMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_93WiS9FA1pEEMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indexYwMGfUOk9wMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_93Yl4ybcEML9Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as indexUj5tCQde81Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/index.vue")
  },
  {
    name: "inventory-bundles-bundle-choices-id",
    path: "/inventory/bundles/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/bundle-choices/[id].vue")
  },
  {
    name: "inventory-bundles-bundle-choices",
    path: "/inventory/bundles/bundle-choices",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/bundle-choices/index.vue")
  },
  {
    name: "inventory-bundles-bundles-id",
    path: "/inventory/bundles/bundles/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/bundles/[id].vue")
  },
  {
    name: "inventory-bundles-bundles",
    path: "/inventory/bundles/bundles",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/bundles/index.vue")
  },
  {
    name: "inventory-bundles",
    path: "/inventory/bundles",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/bundles/index.vue")
  },
  {
    name: "inventory-menus-id-categories-menuCategoryId",
    path: "/inventory/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "inventory-menus-id",
    path: "/inventory/menus/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/menus/[id]/index.vue")
  },
  {
    name: "inventory-menus",
    path: "/inventory/menus",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/menus/index.vue")
  },
  {
    name: "inventory-modifiers",
    path: "/inventory/modifiers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/index.vue")
  },
  {
    name: _91id_934vbrAGlEvsMeta?.name,
    path: "/inventory/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "inventory-modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "inventory-modifiers-modifier-groups",
    path: "/inventory/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/modifiers/modifier-groups/index.vue")
  },
  {
    name: "inventory-products",
    path: "/inventory/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/index.vue")
  },
  {
    name: "inventory-products-product-categories-id",
    path: "/inventory/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/product-categories/[id].vue")
  },
  {
    name: "inventory-products-product-categories",
    path: "/inventory/products/product-categories",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/product-categories/index.vue")
  },
  {
    name: "inventory-products-products-id",
    path: "/inventory/products/products/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/products/[id].vue")
  },
  {
    name: "inventory-products-products",
    path: "/inventory/products/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/inventory/products/products/index.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/merchants.vue")
  },
  {
    name: _91id_93YbHyqFUeyjMeta?.name,
    path: "/point-of-sale/accounts/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/accounts/[id].vue"),
    children: [
  {
    name: "point-of-sale-accounts-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue")
  },
  {
    name: "point-of-sale-accounts-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/accounts/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-accounts",
    path: "/point-of-sale/accounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/accounts/index.vue")
  },
  {
    name: _91id_93yIqJwnKzx3Meta?.name,
    path: "/point-of-sale/connections/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/[id].vue"),
    children: [
  {
    name: "point-of-sale-connections-id-credentials",
    path: "credentials",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/[id]/credentials.vue")
  },
  {
    name: "point-of-sale-connections-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-connections-create-driver",
    path: "/point-of-sale/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/create/[driver].vue")
  },
  {
    name: "point-of-sale-connections-create",
    path: "/point-of-sale/connections/create",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/create/index.vue")
  },
  {
    name: "point-of-sale-connections",
    path: "/point-of-sale/connections",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/connections/index.vue")
  },
  {
    name: _91id_93R5cpug0qhtMeta?.name,
    path: "/point-of-sale/customer-sites/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/customer-sites/[id].vue"),
    children: [
  {
    name: "point-of-sale-customer-sites-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/customer-sites/[id]/index.vue")
  },
  {
    name: "point-of-sale-customer-sites-id-payment-methods",
    path: "payment-methods",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/customer-sites/[id]/payment-methods.vue")
  }
]
  },
  {
    name: "point-of-sale-customer-sites",
    path: "/point-of-sale/customer-sites",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/customer-sites/index.vue")
  },
  {
    name: "point-of-sale-discounts-discounts-id",
    path: "/point-of-sale/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/discounts/discounts/[id].vue")
  },
  {
    name: "point-of-sale-discounts-discounts",
    path: "/point-of-sale/discounts/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/discounts/discounts/index.vue")
  },
  {
    name: "point-of-sale-discounts",
    path: "/point-of-sale/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/discounts/index.vue")
  },
  {
    name: "point-of-sale-employees-id",
    path: "/point-of-sale/employees/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/employees/[id].vue")
  },
  {
    name: "point-of-sale-employees",
    path: "/point-of-sale/employees",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/employees/index.vue")
  },
  {
    name: "point-of-sale-images",
    path: "/point-of-sale/images",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/images/index.vue")
  },
  {
    name: kitchensUZGItNjBO5Meta?.name,
    path: "/point-of-sale/kitchens",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/kitchens.vue"),
    children: [
  {
    name: "point-of-sale-kitchens",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/kitchens/index.vue")
  },
  {
    name: "point-of-sale-kitchens-modifier-groups",
    path: "modifier-groups",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/kitchens/modifier-groups.vue")
  }
]
  },
  {
    name: stockan6A1dxPVuMeta?.name,
    path: "/point-of-sale/stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stock.vue"),
    children: [
  {
    name: "point-of-sale-stock",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stock/index.vue")
  },
  {
    name: "point-of-sale-stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stock/modifier-recipes.vue")
  },
  {
    name: "point-of-sale-stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_93TkpthPaiGUMeta?.name,
    path: "/point-of-sale/stores/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id].vue"),
    children: [
  {
    name: "point-of-sale-stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id]/bookkeeping.vue")
  },
  {
    name: "point-of-sale-stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id]/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id]/kitchen.vue")
  },
  {
    name: "point-of-sale-stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "point-of-sale-stores",
    path: "/point-of-sale/stores",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/stores/index.vue")
  },
  {
    name: "point-of-sale-terminals-colors",
    path: "/point-of-sale/terminals/colors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/colors/index.vue")
  },
  {
    name: "point-of-sale-terminals-folders-id",
    path: "/point-of-sale/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/folders/[id].vue")
  },
  {
    name: "point-of-sale-terminals-folders",
    path: "/point-of-sale/terminals/folders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/folders/index.vue")
  },
  {
    name: _91id_93tBuWsyOlGoMeta?.name,
    path: "/point-of-sale/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/terminals/[id].vue"),
    children: [
  {
    name: "point-of-sale-terminals-terminals-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/terminals/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-terminals-terminals",
    path: "/point-of-sale/terminals/terminals",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/point-of-sale/terminals/terminals/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/profile/index.vue")
  },
  {
    name: "reports-cancellations",
    path: "/reports/cancellations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/cancellations.vue")
  },
  {
    name: "reports-cash-transfers",
    path: "/reports/cash-transfers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/cash-transfers.vue")
  },
  {
    name: "reports-closing-errors",
    path: "/reports/closing-errors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/closing-errors.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/efficiency.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/index.vue")
  },
  {
    name: "reports-payments",
    path: "/reports/payments",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/payments.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/production.vue")
  },
  {
    name: "reports-reservations",
    path: "/reports/reservations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/reservations.vue")
  },
  {
    name: "reports-reviews",
    path: "/reports/reviews",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/reviews.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/reports/tips.vue")
  },
  {
    name: settings89USpWnSM2Meta?.name,
    path: "/settings",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings.vue"),
    children: [
  {
    name: "settings-accounts",
    path: "accounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/accounts.vue")
  },
  {
    name: "settings-finances",
    path: "finances",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/finances.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/index.vue")
  },
  {
    name: "settings-integrations",
    path: "integrations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/integrations.vue")
  },
  {
    name: "settings-operations",
    path: "operations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settings/operations.vue")
  }
]
  },
  {
    name: _91id_93oa1Evm29oJMeta?.name,
    path: "/settlement/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id].vue"),
    children: [
  {
    name: "settlement-channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/index.vue")
  },
  {
    name: "settlement-channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/log.vue")
  },
  {
    name: "settlement-channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "settlement-orders-id",
    path: "/settlement/orders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/orders/[id].vue")
  },
  {
    name: "settlement-orders",
    path: "/settlement/orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/orders/index.vue")
  },
  {
    name: _91id_93Yl4ybcEML9Meta?.name,
    path: "/settlement/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id].vue"),
    children: [
  {
    name: "settlement-store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "settlement-store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "settlement-store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/index.vue")
  },
  {
    name: "settlement-store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/log.vue")
  },
  {
    name: "settlement-store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "settlement-store-settlements",
    path: "/settlement/store-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250127085830/pages/settlement/store-settlements/index.vue")
  }
]