
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexdp8ymD5lDgMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/index.vue?macro=true";
import { default as _91id_93DTmwV1zp5JMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/bundle-choices/[id].vue?macro=true";
import { default as indexpBMNvoox8FMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/bundle-choices/index.vue?macro=true";
import { default as _91id_935nP2dlLz6GMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/bundles/[id].vue?macro=true";
import { default as indexf2e1V2Cu0SMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/bundles/index.vue?macro=true";
import { default as indexSdEgRII5o1Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_93v3os6XkJ3QMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as indexAK5vc6rkCYMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as indexW1x2wETaCUMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/menus/index.vue?macro=true";
import { default as indexqJhaOLozDhMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexOFQpahr6ayMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93KJyBlpuVVFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93gfct4X8UWRMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexO8puSZyXDaMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as indexMXVLcIfeBxMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93o1t6tnDlDyMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as indexIBRtFM3SYjMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93Z46kxQuwrwMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/products/[id].vue?macro=true";
import { default as index7ZInjz7hcFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/products/index.vue?macro=true";
import { default as merchantsBakr1Vvh6zMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/merchants.vue?macro=true";
import { default as _91accountId_93XLq5OqRyBzMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue?macro=true";
import { default as indexnQ66DVWLoCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/accounts/[id]/index.vue?macro=true";
import { default as _91id_93QQqT5Do9cvMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/accounts/[id].vue?macro=true";
import { default as indexpMhRGeiPT9Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/accounts/index.vue?macro=true";
import { default as credentialsI3TbQgwqtlMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as index2Iv4zOEju2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as _91id_93sFJCEKRe5gMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_93z7CH39atu2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as indexvrqQZBI2m0Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indexl0rTZtUtFIMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/index.vue?macro=true";
import { default as indexVlVHoYVwUnMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/customer-sites/[id]/index.vue?macro=true";
import { default as payment_45methodsQ1cGEBK5NFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/customer-sites/[id]/payment-methods.vue?macro=true";
import { default as _91id_93gVgZ0RtfLhMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/customer-sites/[id].vue?macro=true";
import { default as indexedV0xOyZONMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/customer-sites/index.vue?macro=true";
import { default as _91id_9381VhGuPZ93Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as indexHYw5SnzKa8Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexPkj488r30UMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as _91id_93vQ3zlRjZcGMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as indexJlvBd0CvwVMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/employees/index.vue?macro=true";
import { default as indexqed5Uo4OmWMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/images/index.vue?macro=true";
import { default as indexjBz3ElhaWJMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/kitchens/index.vue?macro=true";
import { default as modifier_45groupsSusJXe1VgsMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/kitchens/modifier-groups.vue?macro=true";
import { default as kitchens5ghOYlJW5qMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/kitchens.vue?macro=true";
import { default as index1WpI15UjrNMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stock/index.vue?macro=true";
import { default as modifier_45recipes3otij6SLPRMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stock/modifier-recipes.vue?macro=true";
import { default as variant_45recipesrRZtRUNl4LMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stock/variant-recipes.vue?macro=true";
import { default as stockPpIkkyA1HlMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingypwWUeOia1Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as indexZZ1IQRd74yMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as kitchenuJrLyMXX4RMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id]/kitchen.vue?macro=true";
import { default as stockHRW7QWIbweMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93xQmvXNQG6BMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as indextirMy8M5UhMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/index.vue?macro=true";
import { default as indexahaU3YFKq7Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_93xvSILYA1v3Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as indexMMDPia4Z5BMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as channel_45settlementsF9z7FOoiMUMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue?macro=true";
import { default as indexYKC4ZbNrQYMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as _91id_93yhIL12SwwLMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexVbAbsbzGOzMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as indexsvuszW8rKgMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/profile/index.vue?macro=true";
import { default as cancellationskgnt922S96Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/cancellations.vue?macro=true";
import { default as cash_45transfers8QEZ6MNrFuMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/cash-transfers.vue?macro=true";
import { default as closing_45errorsFHoW01pK7TMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/closing-errors.vue?macro=true";
import { default as downloadrzgnZmxZmPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/download.vue?macro=true";
import { default as efficiencyiSjFzERigxMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/efficiency.vue?macro=true";
import { default as indextQvrIQy7RFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/index.vue?macro=true";
import { default as paymentsQLzoB9qobOMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/payments.vue?macro=true";
import { default as productionoDtLKRtQ8VMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/production.vue?macro=true";
import { default as reservationsKqUBAK6gc5Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/reservations.vue?macro=true";
import { default as reviewsHfCetOaaheMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/reviews.vue?macro=true";
import { default as tips4dLPS1R73AMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/tips.vue?macro=true";
import { default as accountsjHJ1YvUGaqMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/accounts.vue?macro=true";
import { default as financesVPej1Cxx0GMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/finances.vue?macro=true";
import { default as index7T5Vft3zvhMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/index.vue?macro=true";
import { default as integrationsC3dMAojnU2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/integrations.vue?macro=true";
import { default as operationsQTuVl9yDr4Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/operations.vue?macro=true";
import { default as settingsUn4GbXFMD2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings.vue?macro=true";
import { default as indexlFfm8tsA1OMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as log84PLXWkPbxMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as orders7quAMCmbJPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93DK651nob57Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexElwtC6spYnMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93nVsJu9o59OMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_93wyuBy8brwdMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/orders/[id].vue?macro=true";
import { default as index11vThLqgh5Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/orders/index.vue?macro=true";
import { default as bookkeepingrAFjAQw6geMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlementsGUrrnqDWkcMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexIZw5eRSq0EMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logYrRYf9b0W8Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as orderscwRmdXapBhMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_93FHnsTrvynfMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indexiDHVwEckIxMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_93VX1Buz6yB2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as indexEYecr6pIPLMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/index.vue")
  },
  {
    name: "inventory-bundles-bundle-choices-id",
    path: "/inventory/bundles/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/bundle-choices/[id].vue")
  },
  {
    name: "inventory-bundles-bundle-choices",
    path: "/inventory/bundles/bundle-choices",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/bundle-choices/index.vue")
  },
  {
    name: "inventory-bundles-bundles-id",
    path: "/inventory/bundles/bundles/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/bundles/[id].vue")
  },
  {
    name: "inventory-bundles-bundles",
    path: "/inventory/bundles/bundles",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/bundles/index.vue")
  },
  {
    name: "inventory-bundles",
    path: "/inventory/bundles",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/bundles/index.vue")
  },
  {
    name: "inventory-menus-id-categories-menuCategoryId",
    path: "/inventory/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "inventory-menus-id",
    path: "/inventory/menus/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/menus/[id]/index.vue")
  },
  {
    name: "inventory-menus",
    path: "/inventory/menus",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/menus/index.vue")
  },
  {
    name: "inventory-modifiers",
    path: "/inventory/modifiers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/index.vue")
  },
  {
    name: _91id_93gfct4X8UWRMeta?.name,
    path: "/inventory/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "inventory-modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "inventory-modifiers-modifier-groups",
    path: "/inventory/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/modifiers/modifier-groups/index.vue")
  },
  {
    name: "inventory-products",
    path: "/inventory/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/index.vue")
  },
  {
    name: "inventory-products-product-categories-id",
    path: "/inventory/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/product-categories/[id].vue")
  },
  {
    name: "inventory-products-product-categories",
    path: "/inventory/products/product-categories",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/product-categories/index.vue")
  },
  {
    name: "inventory-products-products-id",
    path: "/inventory/products/products/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/products/[id].vue")
  },
  {
    name: "inventory-products-products",
    path: "/inventory/products/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/inventory/products/products/index.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/merchants.vue")
  },
  {
    name: _91id_93QQqT5Do9cvMeta?.name,
    path: "/point-of-sale/accounts/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/accounts/[id].vue"),
    children: [
  {
    name: "point-of-sale-accounts-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue")
  },
  {
    name: "point-of-sale-accounts-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/accounts/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-accounts",
    path: "/point-of-sale/accounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/accounts/index.vue")
  },
  {
    name: _91id_93sFJCEKRe5gMeta?.name,
    path: "/point-of-sale/connections/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/[id].vue"),
    children: [
  {
    name: "point-of-sale-connections-id-credentials",
    path: "credentials",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/[id]/credentials.vue")
  },
  {
    name: "point-of-sale-connections-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-connections-create-driver",
    path: "/point-of-sale/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/create/[driver].vue")
  },
  {
    name: "point-of-sale-connections-create",
    path: "/point-of-sale/connections/create",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/create/index.vue")
  },
  {
    name: "point-of-sale-connections",
    path: "/point-of-sale/connections",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/connections/index.vue")
  },
  {
    name: _91id_93gVgZ0RtfLhMeta?.name,
    path: "/point-of-sale/customer-sites/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/customer-sites/[id].vue"),
    children: [
  {
    name: "point-of-sale-customer-sites-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/customer-sites/[id]/index.vue")
  },
  {
    name: "point-of-sale-customer-sites-id-payment-methods",
    path: "payment-methods",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/customer-sites/[id]/payment-methods.vue")
  }
]
  },
  {
    name: "point-of-sale-customer-sites",
    path: "/point-of-sale/customer-sites",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/customer-sites/index.vue")
  },
  {
    name: "point-of-sale-discounts-discounts-id",
    path: "/point-of-sale/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/discounts/discounts/[id].vue")
  },
  {
    name: "point-of-sale-discounts-discounts",
    path: "/point-of-sale/discounts/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/discounts/discounts/index.vue")
  },
  {
    name: "point-of-sale-discounts",
    path: "/point-of-sale/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/discounts/index.vue")
  },
  {
    name: "point-of-sale-employees-id",
    path: "/point-of-sale/employees/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/employees/[id].vue")
  },
  {
    name: "point-of-sale-employees",
    path: "/point-of-sale/employees",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/employees/index.vue")
  },
  {
    name: "point-of-sale-images",
    path: "/point-of-sale/images",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/images/index.vue")
  },
  {
    name: kitchens5ghOYlJW5qMeta?.name,
    path: "/point-of-sale/kitchens",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/kitchens.vue"),
    children: [
  {
    name: "point-of-sale-kitchens",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/kitchens/index.vue")
  },
  {
    name: "point-of-sale-kitchens-modifier-groups",
    path: "modifier-groups",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/kitchens/modifier-groups.vue")
  }
]
  },
  {
    name: stockPpIkkyA1HlMeta?.name,
    path: "/point-of-sale/stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stock.vue"),
    children: [
  {
    name: "point-of-sale-stock",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stock/index.vue")
  },
  {
    name: "point-of-sale-stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stock/modifier-recipes.vue")
  },
  {
    name: "point-of-sale-stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_93xQmvXNQG6BMeta?.name,
    path: "/point-of-sale/stores/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id].vue"),
    children: [
  {
    name: "point-of-sale-stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id]/bookkeeping.vue")
  },
  {
    name: "point-of-sale-stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id]/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id]/kitchen.vue")
  },
  {
    name: "point-of-sale-stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "point-of-sale-stores",
    path: "/point-of-sale/stores",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/stores/index.vue")
  },
  {
    name: "point-of-sale-terminals-colors",
    path: "/point-of-sale/terminals/colors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/colors/index.vue")
  },
  {
    name: "point-of-sale-terminals-folders-id",
    path: "/point-of-sale/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/folders/[id].vue")
  },
  {
    name: "point-of-sale-terminals-folders",
    path: "/point-of-sale/terminals/folders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/folders/index.vue")
  },
  {
    name: _91id_93yhIL12SwwLMeta?.name,
    path: "/point-of-sale/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/terminals/[id].vue"),
    children: [
  {
    name: "point-of-sale-terminals-terminals-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/terminals/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-terminals-terminals",
    path: "/point-of-sale/terminals/terminals",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/point-of-sale/terminals/terminals/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/profile/index.vue")
  },
  {
    name: "reports-cancellations",
    path: "/reports/cancellations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/cancellations.vue")
  },
  {
    name: "reports-cash-transfers",
    path: "/reports/cash-transfers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/cash-transfers.vue")
  },
  {
    name: "reports-closing-errors",
    path: "/reports/closing-errors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/closing-errors.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/efficiency.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/index.vue")
  },
  {
    name: "reports-payments",
    path: "/reports/payments",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/payments.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/production.vue")
  },
  {
    name: "reports-reservations",
    path: "/reports/reservations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/reservations.vue")
  },
  {
    name: "reports-reviews",
    path: "/reports/reviews",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/reviews.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/reports/tips.vue")
  },
  {
    name: settingsUn4GbXFMD2Meta?.name,
    path: "/settings",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings.vue"),
    children: [
  {
    name: "settings-accounts",
    path: "accounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/accounts.vue")
  },
  {
    name: "settings-finances",
    path: "finances",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/finances.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/index.vue")
  },
  {
    name: "settings-integrations",
    path: "integrations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/integrations.vue")
  },
  {
    name: "settings-operations",
    path: "operations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settings/operations.vue")
  }
]
  },
  {
    name: _91id_93nVsJu9o59OMeta?.name,
    path: "/settlement/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id].vue"),
    children: [
  {
    name: "settlement-channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/index.vue")
  },
  {
    name: "settlement-channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/log.vue")
  },
  {
    name: "settlement-channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "settlement-orders-id",
    path: "/settlement/orders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/orders/[id].vue")
  },
  {
    name: "settlement-orders",
    path: "/settlement/orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/orders/index.vue")
  },
  {
    name: _91id_93VX1Buz6yB2Meta?.name,
    path: "/settlement/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id].vue"),
    children: [
  {
    name: "settlement-store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "settlement-store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "settlement-store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/index.vue")
  },
  {
    name: "settlement-store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/log.vue")
  },
  {
    name: "settlement-store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "settlement-store-settlements",
    path: "/settlement/store-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250124223051/pages/settlement/store-settlements/index.vue")
  }
]