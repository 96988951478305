
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index2ETB31zAIdMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/index.vue?macro=true";
import { default as _91id_936zrm7BK3fqMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/bundle-choices/[id].vue?macro=true";
import { default as indexNxL8VpsK9eMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/bundle-choices/index.vue?macro=true";
import { default as _91id_93s30IdOskMqMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/bundles/[id].vue?macro=true";
import { default as indexXLaDauAOhMMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/bundles/index.vue?macro=true";
import { default as indexerQmY4pTc8Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_93nokZPrfkHjMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as index9nnr0QVkGKMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as index1QBIeMroFbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/menus/index.vue?macro=true";
import { default as indexLgla6N1D9JMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexvjbwHIyat1Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93DiQgs3Ef4tMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93Lgz4Zkf8lbMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexghCWeiYSdIMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as indexVNyMvsRZQrMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93Yl72zrYF0sMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as indexQ5lqnyjLSUMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93CwurpvWLfDMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/products/[id].vue?macro=true";
import { default as indexvW0ye5iwIFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/products/index.vue?macro=true";
import { default as merchantsjPK7fha6VlMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/merchants.vue?macro=true";
import { default as _91accountId_93zr9c1PFotWMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue?macro=true";
import { default as index5IVv57UItqMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/accounts/[id]/index.vue?macro=true";
import { default as _91id_93bmoe311PsgMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/accounts/[id].vue?macro=true";
import { default as index4EqMgNmkCzMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/accounts/index.vue?macro=true";
import { default as credentialsS2AYKO4XocMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as indexxCVaffE3dsMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as _91id_93Yn8ZkAp3MEMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_93G2NWW70zHBMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as indexUDQot1rIMuMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indexCjrjFxd6dhMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/index.vue?macro=true";
import { default as indexYGO1ufiT1OMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/customer-sites/[id]/index.vue?macro=true";
import { default as payment_45methodsbuUtUHyDwQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/customer-sites/[id]/payment-methods.vue?macro=true";
import { default as _91id_93WEmWJP5jGVMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/customer-sites/[id].vue?macro=true";
import { default as indexJ6kEz9F4bCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/customer-sites/index.vue?macro=true";
import { default as _91id_93TukBWoRHoHMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as index56oW0wJIRuMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexQgzuFAuMjBMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as _91id_93MR6PbWT12qMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as index6AmJCQqcPFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/employees/index.vue?macro=true";
import { default as indexQvTbCqLLgXMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/images/index.vue?macro=true";
import { default as indexyg3N7g6rbCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/kitchens/index.vue?macro=true";
import { default as modifier_45groupsFeweWqrf97Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/kitchens/modifier-groups.vue?macro=true";
import { default as kitchens0FXJFXZY1rMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/kitchens.vue?macro=true";
import { default as indexnQy7UwlEqEMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stock/index.vue?macro=true";
import { default as modifier_45recipesTgv33kz635Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stock/modifier-recipes.vue?macro=true";
import { default as variant_45recipesDVXfBw6NkQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stock/variant-recipes.vue?macro=true";
import { default as stockvOLmcqQVQ8Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeeping74BpLSRHZTMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as index28WBIVUH2XMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as kitchene66LoM88dZMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id]/kitchen.vue?macro=true";
import { default as stock2IXnHcVs4SMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93JDyOVk566QMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as indexXfjMKUnkhAMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/index.vue?macro=true";
import { default as index9DXhKbZ5oPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_936xGTy1fTCLMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as index43fXG8su3TMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as channel_45settlementsKprp7hTFJ0Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue?macro=true";
import { default as indexxtK4BWyrjzMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as _91id_93pnSnXxs2EnMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexBEVfasZwmaMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as indexAwAvUdWM2qMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/profile/index.vue?macro=true";
import { default as cancellationsf2671d3DpFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/cancellations.vue?macro=true";
import { default as cash_45transfersWNp3rNJ7FQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/cash-transfers.vue?macro=true";
import { default as closing_45errorsaBHmYrrnB3Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/closing-errors.vue?macro=true";
import { default as downloadmA1He48IbYMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/download.vue?macro=true";
import { default as efficiencyt3xjUXVcYFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/efficiency.vue?macro=true";
import { default as indexbyPIisv0vkMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/index.vue?macro=true";
import { default as paymentserrb0WSDYVMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/payments.vue?macro=true";
import { default as productionxIK4gRmAo4Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/production.vue?macro=true";
import { default as reservationsA613QT0nViMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/reservations.vue?macro=true";
import { default as reviewsr6DaEN1EhpMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/reviews.vue?macro=true";
import { default as tipscmp3Y3mg8TMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/tips.vue?macro=true";
import { default as accountsMNBUD2gWp2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/accounts.vue?macro=true";
import { default as financeswIisYdmsFPMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/finances.vue?macro=true";
import { default as index9pMyqNiRXFMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/index.vue?macro=true";
import { default as integrationsvqyo1kNHbxMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/integrations.vue?macro=true";
import { default as operationsIasE74ju4sMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/operations.vue?macro=true";
import { default as settingsPe2yeqj3lUMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings.vue?macro=true";
import { default as indexNCRT59APgMMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as log5iokBe3j25Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as ordersHGs09lVAKcMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93Qg7eYpBDbMMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexFFo2trn2HKMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_93XwTbYZgJ9zMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_93hIQF32mD6NMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/orders/[id].vue?macro=true";
import { default as indexIZXcdJdi7mMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/orders/index.vue?macro=true";
import { default as bookkeeping8ctGVdXvOCMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlementsS4ThCn21iQMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexZ84BkQL3glMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logMDycyKGu4PMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as ordersw6aT0Jv9elMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_93Ct6kGJCVUzMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indextEQCUN1XU2Meta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_937EATDEsYbOMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as indexgFfG1at1NmMeta } from "/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/index.vue")
  },
  {
    name: "inventory-bundles-bundle-choices-id",
    path: "/inventory/bundles/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/bundle-choices/[id].vue")
  },
  {
    name: "inventory-bundles-bundle-choices",
    path: "/inventory/bundles/bundle-choices",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/bundle-choices/index.vue")
  },
  {
    name: "inventory-bundles-bundles-id",
    path: "/inventory/bundles/bundles/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/bundles/[id].vue")
  },
  {
    name: "inventory-bundles-bundles",
    path: "/inventory/bundles/bundles",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/bundles/index.vue")
  },
  {
    name: "inventory-bundles",
    path: "/inventory/bundles",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/bundles/index.vue")
  },
  {
    name: "inventory-menus-id-categories-menuCategoryId",
    path: "/inventory/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "inventory-menus-id",
    path: "/inventory/menus/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/menus/[id]/index.vue")
  },
  {
    name: "inventory-menus",
    path: "/inventory/menus",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/menus/index.vue")
  },
  {
    name: "inventory-modifiers",
    path: "/inventory/modifiers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/index.vue")
  },
  {
    name: _91id_93Lgz4Zkf8lbMeta?.name,
    path: "/inventory/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "inventory-modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "inventory-modifiers-modifier-groups",
    path: "/inventory/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/modifiers/modifier-groups/index.vue")
  },
  {
    name: "inventory-products",
    path: "/inventory/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/index.vue")
  },
  {
    name: "inventory-products-product-categories-id",
    path: "/inventory/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/product-categories/[id].vue")
  },
  {
    name: "inventory-products-product-categories",
    path: "/inventory/products/product-categories",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/product-categories/index.vue")
  },
  {
    name: "inventory-products-products-id",
    path: "/inventory/products/products/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/products/[id].vue")
  },
  {
    name: "inventory-products-products",
    path: "/inventory/products/products",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/inventory/products/products/index.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/merchants.vue")
  },
  {
    name: _91id_93bmoe311PsgMeta?.name,
    path: "/point-of-sale/accounts/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/accounts/[id].vue"),
    children: [
  {
    name: "point-of-sale-accounts-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/accounts/[id]/accounts/[accountId].vue")
  },
  {
    name: "point-of-sale-accounts-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/accounts/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-accounts",
    path: "/point-of-sale/accounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/accounts/index.vue")
  },
  {
    name: _91id_93Yn8ZkAp3MEMeta?.name,
    path: "/point-of-sale/connections/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/[id].vue"),
    children: [
  {
    name: "point-of-sale-connections-id-credentials",
    path: "credentials",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/[id]/credentials.vue")
  },
  {
    name: "point-of-sale-connections-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-connections-create-driver",
    path: "/point-of-sale/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/create/[driver].vue")
  },
  {
    name: "point-of-sale-connections-create",
    path: "/point-of-sale/connections/create",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/create/index.vue")
  },
  {
    name: "point-of-sale-connections",
    path: "/point-of-sale/connections",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/connections/index.vue")
  },
  {
    name: _91id_93WEmWJP5jGVMeta?.name,
    path: "/point-of-sale/customer-sites/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/customer-sites/[id].vue"),
    children: [
  {
    name: "point-of-sale-customer-sites-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/customer-sites/[id]/index.vue")
  },
  {
    name: "point-of-sale-customer-sites-id-payment-methods",
    path: "payment-methods",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/customer-sites/[id]/payment-methods.vue")
  }
]
  },
  {
    name: "point-of-sale-customer-sites",
    path: "/point-of-sale/customer-sites",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/customer-sites/index.vue")
  },
  {
    name: "point-of-sale-discounts-discounts-id",
    path: "/point-of-sale/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/discounts/discounts/[id].vue")
  },
  {
    name: "point-of-sale-discounts-discounts",
    path: "/point-of-sale/discounts/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/discounts/discounts/index.vue")
  },
  {
    name: "point-of-sale-discounts",
    path: "/point-of-sale/discounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/discounts/index.vue")
  },
  {
    name: "point-of-sale-employees-id",
    path: "/point-of-sale/employees/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/employees/[id].vue")
  },
  {
    name: "point-of-sale-employees",
    path: "/point-of-sale/employees",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/employees/index.vue")
  },
  {
    name: "point-of-sale-images",
    path: "/point-of-sale/images",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/images/index.vue")
  },
  {
    name: kitchens0FXJFXZY1rMeta?.name,
    path: "/point-of-sale/kitchens",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/kitchens.vue"),
    children: [
  {
    name: "point-of-sale-kitchens",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/kitchens/index.vue")
  },
  {
    name: "point-of-sale-kitchens-modifier-groups",
    path: "modifier-groups",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/kitchens/modifier-groups.vue")
  }
]
  },
  {
    name: stockvOLmcqQVQ8Meta?.name,
    path: "/point-of-sale/stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stock.vue"),
    children: [
  {
    name: "point-of-sale-stock",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stock/index.vue")
  },
  {
    name: "point-of-sale-stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stock/modifier-recipes.vue")
  },
  {
    name: "point-of-sale-stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_93JDyOVk566QMeta?.name,
    path: "/point-of-sale/stores/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id].vue"),
    children: [
  {
    name: "point-of-sale-stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id]/bookkeeping.vue")
  },
  {
    name: "point-of-sale-stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id]/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id]/kitchen.vue")
  },
  {
    name: "point-of-sale-stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "point-of-sale-stores",
    path: "/point-of-sale/stores",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/stores/index.vue")
  },
  {
    name: "point-of-sale-terminals-colors",
    path: "/point-of-sale/terminals/colors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/colors/index.vue")
  },
  {
    name: "point-of-sale-terminals-folders-id",
    path: "/point-of-sale/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/folders/[id].vue")
  },
  {
    name: "point-of-sale-terminals-folders",
    path: "/point-of-sale/terminals/folders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/folders/index.vue")
  },
  {
    name: _91id_93pnSnXxs2EnMeta?.name,
    path: "/point-of-sale/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/terminals/[id].vue"),
    children: [
  {
    name: "point-of-sale-terminals-terminals-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/terminals/[id]/channel-settlements.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/terminals/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-terminals-terminals",
    path: "/point-of-sale/terminals/terminals",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/point-of-sale/terminals/terminals/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/profile/index.vue")
  },
  {
    name: "reports-cancellations",
    path: "/reports/cancellations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/cancellations.vue")
  },
  {
    name: "reports-cash-transfers",
    path: "/reports/cash-transfers",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/cash-transfers.vue")
  },
  {
    name: "reports-closing-errors",
    path: "/reports/closing-errors",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/closing-errors.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/efficiency.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/index.vue")
  },
  {
    name: "reports-payments",
    path: "/reports/payments",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/payments.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/production.vue")
  },
  {
    name: "reports-reservations",
    path: "/reports/reservations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/reservations.vue")
  },
  {
    name: "reports-reviews",
    path: "/reports/reviews",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/reviews.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/reports/tips.vue")
  },
  {
    name: settingsPe2yeqj3lUMeta?.name,
    path: "/settings",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings.vue"),
    children: [
  {
    name: "settings-accounts",
    path: "accounts",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/accounts.vue")
  },
  {
    name: "settings-finances",
    path: "finances",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/finances.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/index.vue")
  },
  {
    name: "settings-integrations",
    path: "integrations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/integrations.vue")
  },
  {
    name: "settings-operations",
    path: "operations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settings/operations.vue")
  }
]
  },
  {
    name: _91id_93XwTbYZgJ9zMeta?.name,
    path: "/settlement/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id].vue"),
    children: [
  {
    name: "settlement-channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/index.vue")
  },
  {
    name: "settlement-channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/log.vue")
  },
  {
    name: "settlement-channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "settlement-orders-id",
    path: "/settlement/orders/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/orders/[id].vue")
  },
  {
    name: "settlement-orders",
    path: "/settlement/orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/orders/index.vue")
  },
  {
    name: _91id_937EATDEsYbOMeta?.name,
    path: "/settlement/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id].vue"),
    children: [
  {
    name: "settlement-store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "settlement-store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "settlement-store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/index.vue")
  },
  {
    name: "settlement-store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/log.vue")
  },
  {
    name: "settlement-store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "settlement-store-settlements",
    path: "/settlement/store-settlements",
    component: () => import("/var/www/sites/portal.ultrafedt.dk/releases/20250126214547/pages/settlement/store-settlements/index.vue")
  }
]